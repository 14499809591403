<template>
  <b-container style="text-align: left">
    <b-card class="mt-4">
      <form v-on:submit.prevent="save()" action="" ref="accountForm">
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              label="First Name"
            >
              <b-form-input
                v-model="form.firstname"
                autocomplete="given-name"
                placeholder="First Name"
                required
                :disabled="loading"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Last Name"
            >
              <b-form-input
                v-model="form.lastname"
                autocomplete="family-name"
                placeholder="Last Name"
                required
                :disabled="loading"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Email"
            >
              <b-form-input
                v-model="form.email"
                autocomplete="email"
                placeholder="Email"
                required
                :disabled="loading"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Phone"
            >
              <b-form-input
                v-model="form.phonenumber"
                autocomplete="tel"
                type="tel"
                placeholder="000-000-0000"
                pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                required
                :disabled="loading"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr style="border-top: 1px solid white;">

            <b-button
              @click="togglePasswordEdit()"
              class="mb-3"
              block
            >
              <span v-if="!editPassword"><b-icon-pencil-fill></b-icon-pencil-fill> Edit Password</span>
              <span v-else><b-icon-x-circle-fill></b-icon-x-circle-fill> Cancel Password Edit</span>
            </b-button>
            <b-row v-show="editPassword">
              <b-col cols="12" lg="6">
                <b-form-group
                  label="New Password"
                >
                  <b-form-input
                    v-model="form.password"
                    type="password"
                    autocomplete="new-password"
                    placeholder="New Password"
                    :disabled="!editPassword || loading"
                    required
                    minlength="6"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Confirm New Password"
                >
                  <b-form-input
                    v-model="form.password_confirm"
                    type="password"
                    placeholder="Confirm New Password"
                    autocomplete="new-password"
                    :disabled="!editPassword || loading"
                    required
                    minlength="6"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr>
            <b-overlay :show="loading">
              <b-button
                class="mb-3"
                block
                variant="primary"
                type="submit"
                :disabled="formInvalid() || loading"
              >
                Save
              </b-button>
            </b-overlay>
          </b-col>
          <b-col cols="12" style="text-align: center">
            <small>*all fields required</small>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import AccountService from '@/services/AccountService'

export default {
  data () {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        phonenumber: null,
        password: null,
        password_confirm: null
      },
      editPassword: false,
      loading: false
    }
  },

  created () {
    this.getUserInfo()
  },

  methods: {
    getUserInfo () {
      this.loading = true
      UserService.getRefreshedUser().then(
        (val) => {
          const user = val
          if (user && user.apitoken) {
            this.form = {
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              phonenumber: user.phonenumber,
              password: null,
              password_confirm: null
            }
          }
        }
      ).finally(() => { this.loading = false })
    },

    togglePasswordEdit () {
      this.form.password = null
      this.form.password_confirm = null
      this.editPassword = !this.editPassword
      this.$forceUpdate()
    },

    formInvalid () {
      let isinvalid = false
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'accountForm')) {
        this.$refs.accountForm.elements.forEach(e => {
          if (e.validity.valid === false) {
            isinvalid = true
          }
        })
      } else {
        // refs not available yet, form has not been rendered, default to no
        return true
      }
      return isinvalid
    },

    save () {
      this.loading = true
      // remove delimiters from phone number if there are any
      this.form.phonenumber = (this.form.phonenumber + '').replace(/\D/g, '')

      const data = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        phonenumber: this.form.phonenumber
      }

      if (this.editPassword) {
        data.password = this.form.password
        data.password_confirm = this.form.password_confirm
      }

      AccountService.save(data).then(
        (response) => {
          if (response.data) {
            this.getUserInfo()
            this.editPassword = false
          }
          this.$aimNotify.notify(response)
        }
      ).catch(
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loading = false })
    }
  }
}
</script>
